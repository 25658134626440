.home {
    width: 100%;
    overflow:auto;
    }  
/* .progress-bar {
    float:left; 
    border-right:1px solid #f2f2f2;
    width: 27%;
    height: 100vh;
    text-align: center;
}   
.render-content {
    float: right;
    height: 100vh;
    width: 73%;
} */
    
.progress-bar,.render-content{
    height: 100%;
    width: 27%;
    position: fixed;
    z-index: 1;
    top: 70px;
    overflow-x: hidden;
    padding-top: 20px;
    border-right:1px solid #f2f2f2;
    text-align: center;
 }
 .progress-bar {
    left: 0;
    font-size: 20px;
 }
 .render-content {
    right: 15px;
    width: 72%;
    /* background-image: url("https://www.synthesisretreat.com/hubfs/Synthesis_November2019%20Theme/Images/Artboard+18.png"); */
 }

 .step {
    position: relative;
    min-height: 4em;
    color:rgb(173, 171, 171);
  }
  .step + .step {
    margin-top: 1.5em
  }
  .step > div:first-child {
    position: static;
    height: 0;
  }
  .step > div:not(:first-child) {
    margin-left: 1.5em;
    padding-left: 1em;
  }
  .step.step-active {
    color: #59f06d
  }
  .step.step-deactive {
    color: rgb(211, 29, 29)
  }
  .step.step-active .circle {
    background-color: #59f06d;
  }
  .step.step-deactive .circle {
    background-color: rgb(211, 29, 29)
  }
  
  /* Circle */
  .circle {
    background: rgb(173, 171, 171);
    position: relative;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0 3px #fff;
    left:75px;
  }
  
  /* Vertical Line */
  .circle:after {
    content: ' ';
    position: absolute;
    display: block;
    top: 1px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 100%;
    width: 1px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: -1;
  }
  .step:last-child .circle:after {
    display: none
  }
  
  /* Stepper Titles */
  .title {
    line-height: 1.5em;
    font-weight: bold;
  }
  .caption {
    font-size: 0.8em;
  }

  .welcome-content{
      font-size:18px;
  }
  .payment-content{
    font-size:18px;
  }
  .points{
    text-align: justify;
  }
.healthscreen-demographics label{
  font-size: 20px;
  display: inline-block;
  margin-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
}

.healthscreen-demographics input[type=button] {
  background-color: #eff1f5;
  border-color: #fff;
  color: #bababa;
  padding: 16px 20px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  border: 2px solid #bababa;
}
.healthscreen-demographics input[type=button]:focus {
  background-color: #0d1d42;
  border-color: #fff;
  color: #bababa;
  padding: 16px 20px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  
}
.healthscreen-demographics input[type=button]:hover{
  border-color:#bababa;
}

.assessment input[type=button] {
  background-color: #0d1d42;
  border-color: #fff;
  color: #bababa;
  padding: 16px 20px 20px 20px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  border: 2px solid #bababa;
}

.assessment input[type=button]:focus {
  background-color: #0d1d42;
  border-color: #fff;
  color: #bababa;
  padding: 16px 20px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  
}

.assessment input[type=button]:hover{
  border-color:#bababa;
}

.healthsreen-form{
    border: none;
      border-bottom: 1px solid #ccc;
      background: none;
      padding-left: 0;
      padding-right: 0;
      line-height: 1.5rem;
      display: inline-block;
      width: 35%;
      text-align: center;
      font-size: 15px;
  }