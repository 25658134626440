@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
* {
    margin: 0;
    font-family: "Quicksand";
    color: #333;
  }
.navbar {
    padding: 20px;
    display: flex;
    align-items: center;
    /* max-width: 600px; */
    margin: 0 auto;
    border-bottom: 1px solid #f2f2f2;
  }
  .navbar h1 {
    color: #f1356d;
  }
  .navbar .links {
    margin-left: auto;
  }
  .navbar a {
    margin-left: 16px;
    text-decoration: none;
    padding: 6px;
  }
  .navbar a:hover {
    color: #f1356d;
  }
  