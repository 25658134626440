.questionnarie-form .questions label{
    font-size: 20px;
    display: inline-block;
    margin-bottom: 0.5rem;
    padding-left: 0;
    padding-right: 0;
}
.questions{
    white-space: pre-line
}
.questionnarie-form{
    padding:15px
}
.questionnarie-form .questions .form-control{
    border: none;
    border-bottom: 1px solid #ccc;
    background: none;
    padding-left: 0;
    padding-right: 0;
    line-height: 1.5rem;
    display: inline-block;
    width: 35%;
    text-align: center;
    font-size: 15px;
}
input:focus,select:focus{
    border:none;
    border-bottom: 1px solid #ccc;
    outline: none;
}

/* .radio-toolbar input[type="radio"] {
    display: none;
  }
  
  .radio-toolbar label {
    display: inline-block;
    background-color: #ddd;
    padding: 4px 11px;
    font-family: Arial;
    font-size: 16px;
    cursor: pointer;
  }*/
  .radio-toolbar label {
    cursor: pointer; 
  }
  
  .radio-toolbar input[type="radio"]:checked+label {
    background-color: rgb(226 222 222 / 47%);
  } 
  .text-danger{
color: red;
  }