.buttonStyle {
    background-color: #04AA6D;
    color: white;
    padding: 14px 25px;
    margin: 30px 0;
    border: none;
    cursor: pointer;
    width: 100;
    position: relative;
    text-align: center;
  }
  .buttonStyle:hover{
    opacity: 0.8;
  }

  .start {
    background-color: #04AA6D;
    color: white;
    padding: 14px 25px;
    margin: 30px 0;
    border: none;
    cursor: pointer;
    position: relative;
    text-align: center;
  }

  .start:hover{
    opacity: 0.8;
  }

  .question-button{
    background-color: #bac2bf;
    color: white;
    padding: 12px 25px;
    margin: 30px 0;
    border: 1px solid #dfe4e2;
    cursor: pointer;
    width: 100;
    position: relative;
    text-align: center;
    background-color: rgb(255, 255, 255);
    color: rgb(128, 128, 128);
}
.question-button:hover{
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 12px 0px;
}
.divider{
  width:50px;
  height:auto;
  display:inline-block;
}
